const htmlBody = {
  nav: `
  <a class="logo--nav-href" href="./home.html">
  <div class="logo-container--nav">
    <img class="logo--img" src="https://scienceofmusicschool.com//logo.b09bf50e.png" />
    <p class="logo--text">Science of Music</p>
  </div>
  </a>
  <ul class="list-container--nav">
    <li class="item--nav">
      <a href="./learn.html">Music Courses</a>
    </li>
  </ul>
  <div class="lang-container--nav">
    &nbsp;
  </div>
  `,
};

const langFileHome = {
  english: {
    lang: "en",
    dir: "ltr",
    htmlDescription: `
      Science of Music is a new approach to music education that provides a deep understanding of music and the possibility to learn advanced music skills. Come learn how to sing in tune, how to compose, how to improvise, and how to develop and ear for music. We offer private online classes. We offer in-person lessons in Jerusalem, Israel. We offer online content.
      `,
    pageTitle: " Science of Music | Comprehensive Music Education",
    logo: "Science of Music",
    home: "Home",
    read: "Read",
    learn: "Learn",
    welcome: "Welcome to Science of Music",
    dedication: " A website dedicated to understanding the nature of music.",
    pOne: `
      The science of music is the study of pitches and their
      interrelationships which deliver meaningful experiences to us. It’s
      musicians.
      `,
    pTwo: `
      The Science of Music School is an initiative by David Frankel that
      aims to provide people with a clearer understanding of music. Our
      articles and videos offer everyone a chance to discover the nature
      of Music. Our private classes offer people of all levels a chance to
      develop musicality and learn advanced musical skills. Skills such as
      singing in tune, playing music freely and naturally, improvisation,
      and composition, and all accompanied by a clear understanding of music.
      `,
    pThree: `
      David Frankel devotes his time to opening the doors to the language
      of music for everyone. David’s new method conveys the nature of
      music with utmost clarity, allowing people to learn skills that were
      previously thought to be unattainable, and allowing “non-musical”
      people to become musical. As David says, “Music is a language that
      can be learned just like we can learn verbal languages.”
      `,
    quote: `"Although Music appears magical, it is a science. "`,
    david: "David Frankel",
    inTouch: "Get in touch",
    subscribe: "Subscribe",
    donate: "Donate",
    location: "Jerusalem, Israel",
    name: "Name",
    email: "Email",
    scienceEmail: "scienceofmusic@gmail.com",
    message: "Message",
    submit: "Submit",
    formQuote: `“What I have achieved by industry and practice, anyone else with tolerable natural gift and ability can also achieve.”`,
    formQuoteAuth: "J. S. Bach",
    weekly: "Subscribe for weekly updates!",
    supportPOne: "Support the growth of Science of Music!",
    supportPTwo: "For inquiries please contact here",
  },
  nederlands: {
    lang: "nl",
    dir: "ltr",
    htmlDescription: `
      Werenschap of Muziek is een nieuwe benadering van muziekonderwijs die een diep begrip van muziek biedt en de mogelijkheid biedt om geavanceerde muziekvaardigheden te leren. Kom leren hoe je zuiver kunt zingen, componeren, improviseren, en hoe je een muziekale oor kunt ontwikkelen. Wij bieden privé online lessen aan. Wij bieden persoonlijke lessen aan in Jeruzalem, Israël. Wij bieden online-inhoud.
      `,
    pageTitle: "Wetenschap van Muziek | begrijpelijke muziekonderwijs",
    logo: "Wetenschap van Muziek",
    home: "Startpagina",
    read: "Leren",
    learn: "Lezen",
    welcome: "Welkom aan Wetenschap van Muziek",
    dedication: "Een website gewijd aan het begrijpen van muziek.",
    pOne: `
       De wetenschap van Muziek is de studie van toonhoogtes en hun
       relaties die aan ons zinvolle ervaringen brengt. Deze wetenschap
       werd eeuwen lang door muzikanten ontwikkeld.
      `,
    pTwo: `
      De Wetenschap van muziek School is een initiatief van David
      Fränkel die mensen een duidelijk begrip van muziek doelt te
      brengen. Onze artikelen en video’s bieden aan iedereen de kans om
      dichter bij muziek te komen. Onze privé lessen bieden aan iedereen
      de kans om muzikaliteit te ontwikkelen. Bij ons kunnen mensen
      leren juist te zingen, muzikaal gehoor te ontwikkelen, muziek
      spelen improviseren en componeren met vrijheid. Onze nieuwe
      benadering biedt aan iedereen de kans om muzikaal te zijn en een
      duidelijk begrip van muziek te bevatten.
      `,
    pThree: `
      David Fränkel wijdt zijn leven aan de taak om de deur aan begrip
      van muziek voor iedereen te openen. Zijn nieuwe methode brengt
      muzikaal begrip over met duidelijkheid, wat mensen de kans geeft
      om vaardigheden te leren die tot nu toe als onbereikbaar beschouwd
      werden. Het laat “niet muzikale” mensen muzikaal worden. David
      zegt: “muziek is een taal dat je kunt leren net als je een
      spreektaal kunt leren”.
      `,
    quote: `"Hoewel muziek magisch lijkt, is het een wetenschap."`,
    david: "David Fränkel",
    inTouch: "Contact",
    subscribe: "Abonneren",
    donate: "Doneren",
    location: "Jeruzalem, Isräel",
    name: "Naam",
    email: "Email",
    scienceEmail: "wetenschapvanmuziek@gmail.com",
    message: "Bericht",
    submit: "Stuur",
    formQuote: `
    "Wat ik heb bereikt door hard werken en oefening, kan ook door iedereen met een redelijk natuurlijk talent en vermogen worden bereikt."`,
    formQuoteAuth: "J.S Bach",
    weekly: "Abonneren voor ons nieuwsbrief!",
    supportPOne: "Steun de groei van de Wetenschap van Muziek!",
    supportPTwo: "Voor vragen kunt u hier contact opnemen.",
  },
  yiddish: {
    lang: "he",
    dir: "rtl",
    htmlDescription:
      "חכמת הנגינה איז א פראיעקט געפירט דורך משה דוד פרענקיל וואס געבט מענטשן די געלעגנהייט צו לערנען פארשטיין מוזיק.",
    pageTitle: "חכמת הנגינה | מוזיק פארשטענדעניש",
    logo: "",
    home: "היים",
    read: "לייען",
    learn: "לערן",
    welcome: "ברוך הבא צו חכמת הנגינה",
    dedication: "א מקום פאר מוזיק פארשטענדעניש",
    pOne: `
      "הוא (הגר"א) היה אומר אז כי רוב טעמי תורה וסודות שירי הלויים, וסודות תיקוני הזהר, אי אפשר לידע בלעדה - בלי חכמת המוזיקה אי אפשר ללמוד סודות התורה. "  
         פאת שולחן, הקדמה
      `,
    pTwo: `
      דעיע פראיעקט, געפירט דורך משה דוד פרענקיל, ציהלט צו פיטערן דער עולם מיט א טיפערע פארשטאנד פון מוזיק. עס געבט יעדעם די מעגליכקייט זיך צו פארטיפן אין הבנה פון מוזיק דורכ׳ן לייענען און הערן וואס מען שטעלט דא צי. אויך שטעלט מען דא צי דער מעגליכקייט צו באקומען פריוואטע חינוך אין מוזיק פון משה דוד ווי מען קען ענטוויקעלען מוזיק טאלאנטן ווי זינגען ריכטיג, שפילן דורכ׳ן אויער און פארשטיין מוזיק, און שרייבן אייגענער מוזיק. 
      `,
    pThree: `
      משה דוד געבט זיין לעבן צו עפענען א וועג איבער צו געבן מוזיק פארשטאנד און טאלאנטן פאר יעדעם. זיינע הצלחות דערינען האבן עם געפיהרט אויפ׳ן דרך ווייטער צו ענטוויקעלען די געדאנק צו קענען איבער געבן מוזיקאלישע טאלאנטן פאר יעדעם און עס מציע צו זיין פאר׳ן עולם. ווי משה דוד זאגט, ״מוזיק איז א שפראך וואס יעדער קען לערנען פונקט ווי מען קען לערנען ענגליש״.
      `,
    quote: `"מוזיק איז טאקע א פלא, עס איז אבער אויך א ידיעה."`,
    david: "משה דוד פרענקיל",
    inTouch: "שרייבט צו מיר",
    subscribe: "הערט פון מיר",
    donate: "ביטר א ביסל גאלט",
    location: "ירושלים",
    name: "נאמאן",
    email: "אימייל",
    scienceEmail: "scienceofmusic@gmail.com",
    message: "עפעס אנדערש",
    submit: "שיק",
    formQuote: `„וואס איך האָב ער­reיכט דורך האר­ט אַר­בעט און פּראַקטיס, קען עמעצער אַנדערש מיט לערב­לעכן נאַטור­לעכן שטאַרקייט און פי­קאדיקייט אויך ער­רעיכ­ן.“`,
    formQuoteAuth: "י. ס. באך",
    weekly: "שרייבט זיך איין פאר וואכנטליכער נייעס!",
    supportPOne: "שטיצט די וואַקסן פון מוזיק־וויסנשאַפֿט!",
    supportPTwo: "פֿאַר אינפֿאָרמאַציע, ביטע קאָנטאַקט דאָ.",
  },
  hebrew: {
    lang: "he",
    dir: "rtl",
    htmlDescription:
      "חכמת הנגינה היא גישה חדשה לחינוך מוזיקלי המספקת הבנה עמוקה של מוזיקה ואפשרות ללמוד מיומנויות מוזיקה מתקדמות. בואו ללמוד איך לשיר בטון הנכון, להלחין, איך לאלתר, ואיך לפתח אוזן למוזיקה. אנו מציעים שיעורים פרטיים באינטרנט. אנו מציעים שיעורים אישיים בירושלים. ואנו מציעים תוכן מקוון.",
    pageTitle: "חכמת הנגינה | חינוך מוזיקלי מקיף",
    logo: "חכמת הנגינה",
    home: "בית",
    read: "קריאה",
    learn: "למידה",
    welcome: "ברוכים הבאים לחכמת הנגינה",
    dedication: "אתר המוקדש להבנת חכמת המוזיקה.",
    pOne: `
      חכמת הנגינה היא לימוד הצלילים ויחסי הגומלין ביניהם אשר מספקים לנו
      חוויות משמעותיות. זוהי לימוד וחקירה אשר פותחה במשך מאות שנים על
      ידי המוזיקאים הגדולים ביותר.
      `,
    pTwo: `
      לימוד 'חכמת הנגינה' היא יוזמה של דוד פרנקל שמטרתה לספק לאנשים הבנה
      ברורה יותר של מוזיקה. המאמרים והסרטונים שלנו מציעים לכולם הזדמנות
      לגלות את טבעה של המוזיקה. השיעורים הפרטיים שלנו מציעים לאנשים מכל
      הרמות הזדמנות לפתח מוזיקליות וללמוד מיומנויות מוזיקליות מתקדמות.
      מיומנויות כמו שירה בטון הנכון, ניגון מוזיקה באופן חופשי וטבעי,
      אלתור והלחנה, והכל בליווי הבנה ברורה של המוזיקה.
      `,
    pThree: `
      דוד פרנקל מקדיש את זמנו לפתיחת הדלתות של המוזיקה לכולם. השיטה
      החדשה של דוד מעבירה את המוזיקה באופן בהיר, מאפשרת לאנשים ללמוד
      מיומנויות שבעבר נחשבו בלתי ניתנות להשגה, ומאפשרת לאנשים "לא
      מוזיקליים" להפוך למוזיקליים. כפי שדוד אומר, "מוזיקה היא שפה הניתנת
      ללימוד בדיוק כמו שפה מילולית."
      `,
    quote: `"למרות שמוזיקה נראית קסומה, באמיתותה היא חכמה הנתנת ללימוד."`,
    david: "דוד פרנקל",
    inTouch: "צור קשר",
    subscribe: "תתעדכן",
    donate: "תרומה",
    location: "ירושלים",
    name: "שם",
    email: "מייל",
    scienceEmail: "scienceofmusichebrew@gmail.com",
    message: "הודעה",
    submit: "שלח",
    formQuote: `"מה שהשגתי בעבודה קשה ובתרגול, כל אחד עם כישרון טבעי וסביר יכול גם להשיג."`,
    formQuoteAuth: "י. ס. באך",
    weekly: "קבל עדכונים שבועיים!",
    supportPOne: "תמכו בצמיחת מדע המוזיקה!",
    supportPTwo: "לפניות אנא צרו קשר כאן",
  },
};

const langFileLearn = {
  english: {
    lang: "en",
    dir: "ltr",
    htmlDescription: `
    Learn to understand music. Learn to sing in tune. Learn to compose. Learn to improvise. Develop musicality. Learn music theory. Learn to play by ear. Learn to understand classical music. Private music lessons. Online music lessons.
    `,
    pageTitle: "Science of Music | Courses",
    logo: "Science of Music",
    home: "Home",
    read: "Read",
    learn: "Learn",
    lovers: "For Music Lovers",
    broadencap: "BROADEN YOUR HORIZON",
    noexperience: "No experience needed",
    understandMusic: "Understand music",
    classical: "Classical music",
    history: "Music history",
    theory: "Music theory",
    session: "$75 / session",
    sessions: "$650 / 10 sessions",
    inquire: "Inquire",
    popular: "Most Popular",
    everybody: "For Everyone",
    developcap: "DEVELOP MUSICALITY",
    playing: "Play music",
    intune: "Sing in tune",
    eartraining: "Ear training",
    musicians: "For Musicians",
    sharpencap: "SHARPEN YOUR SKILLS",
    arrange: "Arrange",
    compose: "Compose",
    improvise: "Improvise",
    playby: "Play by Ear",
    scroll: "Scroll down for more",
    develop: "Develop Musicality",
    choose: "--Choose your main focus--",
    whatlearn: "What you'll learn:",
    willlearn: `
    In this course you will learn music skills that have until now
    been unavailable to the wider puclic. David Frankel, a
    classically trained musician, has made it his mission to train
    the so called 'non-musical people' to perform some of the most
    advanced music skills, such as composing and playing by ear.
    `,
    toknow: "What you need to know:",
    online: "Online / In-person learning",
    group: "Individual / Group learning",
    ages: "For ages 10 - 120",
    piano: "Piano / keyboard required",
    taughtby: "All lessons taught by David Frankel",
    david: "Name",
    scienceEmail: "Email",
    message: "Message",
    submit: "Submit",
  },
  nederlands: {
    lang: "nl",
    dir: "ltr",
    htmlDescription:
      "Leer muziek begrijpen. Leer in toon zingen. Leer componeren. Leer improviseren. Ontwikkel musicaliteit. Leer muziektheorie. Leer spelen op gehoor. Leer klassieke muziek begrijpen. Privé muzieklessen. Online muzieklessen.",
    pageTitle: "Wetenschap van Muziek | Cursussen",
    logo: "Wetenschap van Muziek",
    home: "Startpagina",
    read: "Lezen",
    learn: "Leren",
    lovers: "Voor Muziekliefhebbers",
    broadencap: "VERBREED JE HORIZON",
    noexperience: "Geen ervaring nodig",
    understandMusic: "Begrijp muziek",
    classical: "Klassieke muziek",
    history: "Muziekgeschiedenis",
    theory: "Muziektheorie",
    session: "$75 / sessie",
    sessions: "$650 / 10 sessies",
    inquire: "Informeren",
    popular: "Meest Populair",
    everybody: "Voor Iedereen",
    developcap: "ONTWIKKEL MUSICALITEIT",
    playing: "Speel muziek",
    intune: "Zing in toon",
    eartraining: "Oortraining",
    musicians: "Voor Musici",
    sharpencap: "SNEEUW JE VAARDIGHEDEN",
    arrange: "Arrangeren",
    compose: "Componeren",
    improvise: "Improviseren",
    playby: "Op gehoor spelen",
    scroll: "Scroll naar beneden voor meer",
    develop: "Ontwikkel Musicaliteit",
    choose: "--Kies je belangrijkste focus--",
    whatlearn: "Wat je zult leren:",
    willlearn: `
    In deze cursus zul je muziekvaardigheden leren die tot nu toe niet beschikbaar waren voor het brede publiek. David Frankel, een klassiek opgeleide muzikant, heeft het tot zijn missie gemaakt om de zogenaamde 'niet-musicale mensen' te trainen in enkele van de meest geavanceerde muziekvaardigheden, zoals componeren en spelen op gehoor.
    `,
    toknow: "Wat je moet weten:",
    online: "Online / Persoonlijk leren",
    group: "Individueel / Groepsleren",
    ages: "Voor leeftijden 10 - 120",
    piano: "Piano / keyboard vereist",
    taughtby: "Alle lessen worden gegeven door David Frankel",
    david: "Naam",
    scienceEmail: "E-mail",
    message: "Bericht",
    submit: "Verzenden",
  },
  yiddish: {
    lang: "he",
    dir: "rtl",
    htmlDescription: "",
    pageTitle: "לערן | חכמת הנגינה",
    logo: "חכמת הנגינה",
    home: "היים",
    read: "לייען",
    learn: "לערן",

    lovers: "For Music Lovers",
    broadencap: "BROADEN YOUR HORIZON",
    noexperience: "No experience needed",
    understandMusic: "Understand music",
    classical: "Classical music",
    history: "Music history",
    theory: "Music theory",
    session: "$75 / session",
    sessions: "$650 / 10 sessions",
    inquire: "Inquire",
    popular: "Most Popular",
    everybody: "For Everyone",
    developcap: "DEVELOP MUSICALITY",
    playing: "Play music",
    intune: "Sing in tune",
    eartraining: "Ear training",
    musicians: "For Musicians",
    sharpencap: "SHARPEN YOUR SKILLS",
    arrange: "Arrange",
    compose: "Compose",
    improvise: "Improvise",
    playby: "Play by Ear",
    scroll: "Scroll down for more",
    develop: "Develop Musicality",
    choose: "--Choose your main focus--",
    whatlearn: "What you'll learn:",
    willlearn: `
    In this course you will learn music skills that have until now
    been unavailable to the wider puclic. David Frankel, a
    classically trained musician, has made it his mission to train
    the so called 'non-musical people' to perform some of the most
    advanced music skills, such as composing and playing by ear.
    `,
    toknow: "What you need to know:",
    online: "Online / In-person learning",
    group: "Individual / Group learning",
    ages: "For ages 10 - 120",
    piano: "Piano / keyboard required",
    taughtby: "All lessons taught by David Frankel",
    david: "Name",
    scienceEmail: "Email",
    message: "Message",
    submit: "Submit",
  },
  hebrew: {
    lang: "he",
    dir: "rtl",
    htmlDescription:
      "תלמדו להבין מוזיקה. תלמדו לשיר בטון הנכון. תלמדו להלחין, לאלתר ולפתח מוזיקליות. תלמדו את תורת המוזיקה, איך לנגן לפי שמיעה, ולהבין מוזיקה קלאסית. שיעורי מוזיקה פרטיים. שיעורי מוזיקה מקוונים.",
    pageTitle: "קורסים | חכמת הנגינה",
    logo: "חכמת הנגינה",
    home: "בית",
    read: "קריאה",
    learn: "למידה",

    lovers: "For Music Lovers",
    broadencap: "BROADEN YOUR HORIZON",
    noexperience: "No experience needed",
    understandMusic: "Understand music",
    classical: "Classical music",
    history: "Music history",
    theory: "Music theory",
    session: "$75 / session",
    sessions: "$650 / 10 sessions",
    inquire: "Inquire",
    popular: "Most Popular",
    everybody: "For Everyone",
    developcap: "DEVELOP MUSICALITY",
    playing: "Play music",
    intune: "Sing in tune",
    eartraining: "Ear training",
    musicians: "For Musicians",
    sharpencap: "SHARPEN YOUR SKILLS",
    arrange: "Arrange",
    compose: "Compose",
    improvise: "Improvise",
    playby: "Play by Ear",
    scroll: "Scroll down for more",
    develop: "Develop Musicality",
    choose: "--Choose your main focus--",
    whatlearn: "What you'll learn:",
    willlearn: `
    In this course you will learn music skills that have until now
    been unavailable to the wider puclic. David Frankel, a
    classically trained musician, has made it his mission to train
    the so called 'non-musical people' to perform some of the most
    advanced music skills, such as composing and playing by ear.
    `,
    toknow: "What you need to know:",
    online: "Online / In-person learning",
    group: "Individual / Group learning",
    ages: "For ages 10 - 120",
    piano: "Piano / keyboard required",
    taughtby: "All lessons taught by David Frankel",
    david: "Name",
    scienceEmail: "Email",
    message: "Message",
    submit: "Submit",
  },
};

export const courseForm = {
  lovers: {
    title: "Broaden Your Horizon",

    el1: "Understand music",
    el2: "Classical music",
    el3: "Music history",
    el4: "Music theory",
    learn: `
    This course is a path I developed for anyone wishing to understand music without playing or practicing it. The study of music is of interest to any curious person. Regardless of your level of musicality, this course will teach you to comprehend music with clarity.
    `,
    experience: "No prior experience needed",
  },
  develop: {
    title: "Develop Musicality",
    el1: "Play music",
    el2: "Understand music",
    el3: "Sing in tune",
    el4: "Ear training",
    learn: `
    This course is a path I developed for anyone wishing to develop musicality. Regardless of the talents you’re born with, this course will bring you into the world of music. 
    `,
    experience: "No prior experience needed",
  },
  sharpen: {
    title: "Sharpen Your Skills",
    el1: "Arrange",
    el2: "Compose",
    el3: "Improvise",
    el4: "Play by ear",
    learn: `
    This course is a path I developed for musicians wishing to expand and solidify their skills. Regardless of the education and practice you’ve had so far, this course will teach you the creative skills of the next level. 
    `,
    experience: "Playing knowledge required",
  },
};

export const titleTopics = {
  science: ["Music Fundamentals"],
  genres: ["Music Genres"],
  education: ["Music Education"],
  math: ["Musical Math"],
  perspective: ["A Musician's Perspective"],
  terminology: ["Music Terminlogy"],
};

export const musicGenres = {
  science: [
    // {
    //   id: "science",
    //   title: "Mission statement",
    //   text: " At the Science of Music School, we teach people to view music through the eyes of a composer. We help them understand the laws of music with the same clarity as one would grasp the principles of cooking.",
    //   href: "science-of-music.html",
    // },
    {
      id: "science",
      title: "Music",
      text: "What's music? How does it conduct such magic upon us? How is it created?",
      href: "what-is-music.html",
    },
    {
      id: "science",
      title: "The scale",
      text: "What's the scale of music? How is a music scale used? Do I have to know the music scale?",
      href: "the-music-scale.html",
    },
    {
      id: "science",
      title: "Melody",
      text: "What's a melody? Where do melodies come from? How do I create a melody?",
      href: "melody.html",
    },
    {
      id: "science",
      title: "Harmony",
      text: "What's harmony? How does harmony work? How do I create harmony?",
      href: "harmony.html",
    },
    {
      id: "science",
      title: "Chords",
      text: "What are chords? How do chords work? How do I create chords?",
      href: "chords.html",
    },
    {
      id: "science",
      title: "Rhythm",
      text: "What's rhythm? What makes music rhythmic? How do I create Rhythm?",
      href: "rhythm.html",
    },
  ],
  genres: [
    {
      title: "Classical music",
      text: "What's classical music? Is all old music classical? Can I compose classical music today?",
      id: "genres",
      href: "classical-music.html",
    },
    {
      title: "Jazz",
      text: "What's Jazz? Why do people like jazz in bars? What makes something jazzy?",
      id: "genres",
      href: "jazz.html",
    },
    {
      title: "Film music",
      text: "What's film music? Is film music the same as classical music? Is film music unique?",
      id: "genres",
      href: "film-music.html",
    },
    {
      title: "Jewish music",
      text: "What's Jewish music? Where does Jewish music come from? What is Jewish music like? ",
      id: "genres",
      href: "jewish-music.html",
    },
  ],
  education: [
    {
      href: "integrity-of-teaching.html",
      id: "education",
      title: "The integrity of teaching",
      text: "What's a music teacher? What do music teachers teach? How can I inspire music in my students?",
    },
    {
      href: "music-education.html",
      id: "education",
      title: "Music education",
      text: "What's the problem with music education? How can we foster a connection between children and music?",
    },
    {
      href: "music-and-memory.html",
      id: "education",
      title: "Music and memory",
      text: "Does music improve memory? How can I remember an hour's long piece by heart? What kind of memory is ignited when playing music?",
    },
  ],
  math: [
    {
      id: "math",
      href: "music-and-math-i",
      title: "The math of music I",
      text: "Why does music touch us so deeply? What's sound? What's a musical pitch?",
    },
    {
      id: "math",
      href: "music-and-math-ii",
      title: "The math of music II",
      text: "What's the difference between an adult's voice and a child's? How do I know where I can stop in the middle of a piece?",
    },
    {
      id: "math",
      href: "music-and-math-iii",
      title: "The math of music III",
      text: "Do we really just produce one pitch at a time? Why do certain notes work together as a chord?",
    },
  ],
  perspective: [
    {
      id: "perspective",
      href: "performance.html",
      title: "The art of performing",
      text: "What does it truly mean to be a performer? What does a performance demand from the artist?",
    },
    {
      id: "perspective",
      href: "language.html",
      title: "Is music a language",
      text: "What is it that music expresses that words cannot do? What does music have in common with language?",
    },
  ],
  terminology: [
    {
      id: "terminology",
      href: "arrangement.html",
      title: "What is arranging",
      text: "What does it mean to arrange a piece of music? Why does a familiar song sound so different when it’s rearranged?",
    },
    {
      id: "terminology",
      href: "modulation.html",
      title: "The art of modulation",
      text: "What's modulation? Why do we modulate? How can I modulate elegantly?",
    },
    {
      id: "terminology",
      href: "sequence.html",
      title: "Sequence in music",
      text: "What characteristics do many acclaimed pieces and songs share? And what can these qualities teach us about Life, similar to lessons learned from other fields?",
    },
    {
      id: "terminology",
      href: "circle-of-fifths.html",
      title: "Circle of fifths",
      text: "What's the circle of fifths? And why is it called so?",
    },
    {
      id: "terminology",
      href: "tuning.html",
      title: "Musical tuning",
      text: "What does it mean for an instrument to be tuned? According to what measure are instruments tuned? Is tuning done in a universal way?",
    },
    {
      id: "terminology",
      href: "notation.html",
      title: "Musical notation",
      text: "What's musical notation? Why did it start? Is notation still necessary?",
    },
  ],
};
