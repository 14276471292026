class HomeView {
  #homePage = document.querySelector(".home-page--container");
  #langIcon = document.querySelector(".lang--icon");
  #langForm = document.querySelector(".section-form--lang");
  #langFormInner = document.querySelector(".form--lang");
  #allLangTexts = document.querySelectorAll("[data-lang]");
  #allLangs = document.querySelectorAll(".lang");
  #html = document.querySelector("html");
  #navhrefs = document.querySelectorAll(".nav--href");
  #allHomeAnimations = document.querySelectorAll(".hidden-animation");

  _hide(el) {
    el.classList.add("hidden");
  }

  _reveal(el) {
    el.classList.remove("hidden");
  }

  listenHomeAnimations() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    this.#allHomeAnimations.forEach((anime) => {
      observer.observe(anime);
    });

    /*
      {
        root: document.querySelector("body"),
        rootMargin: "0px",
        threshold: 1,
      }*/
  }

  _closeForm(form, secForm) {
    form.classList.add("collapse");
    secForm.classList.add("fade-out");

    setTimeout(() => {
      form.classList.remove("collapse");
      secForm.classList.remove("fade-out");
      this._hide(secForm);
    }, 300);
  }

  setHomeLang() {
    const lang = window.location.hash.substring(1);
    this._translateElements(lang).then(() => {
      this.#homePage.classList.remove("invisible");
    });
  }

  _translateElements(lang) {
    // translate text
    this.#allLangTexts.forEach((text) => {
      const textId = text.dataset.lang;
      if (!textId) return;
      text.textContent = langFileHome[lang][textId];
    });

    // html direction
    this.#html.dir = langFileHome[lang].dir;
    this.#html.lang = langFileHome[lang].lang;

    // update cur language in language form
    this.#allLangs.forEach((el) => {
      if (el.id === lang) el.classList.add("underline");
      if (!(el.id === lang)) el.classList.remove("underline");
    });

    // update language hash in url
    this.setHrefs(this.#navhrefs, lang);

    return Promise.resolve();
  }

  _translate(lang) {
    // translate elements
    this._translateElements(lang);
    // close language form
    this._closeForm(this.#langFormInner, this.#langForm);
  }

  listenLang() {
    this.#langIcon?.addEventListener("click", () => {
      this._reveal(this.#langForm);
    });
    this.#allLangs.forEach((lang) => {
      lang.addEventListener("click", (e) => {
        const langId = e.target?.id;
        if (!langId) return;
        this._translate(langId);

        // update language hash in url
        this.setHrefs(this.#navhrefs, langId);

        // update hash in current url
        window.location.hash = "";
        window.location.hash = `${langId}`;
      });
    });
  }

  setHrefs(navList, langId) {
    navList.forEach((link) => {
      // will need to eventually change to scienceofmusicschool.com
      link.href = `http://localhost:1234/${link.dataset.lang}.html#${langId}`;
    });
  }

  listenForm(contactIcons, exitIcons, body) {
    contactIcons?.forEach((icon) => {
      icon.addEventListener("click", (e) => {
        // find relative form
        const formId = e.target.closest(".icon--contact").id;
        if (!formId) return;

        this._reveal(document.querySelector(`.${formId}`));
      });
    });

    exitIcons?.forEach((icon) => {
      icon.addEventListener("click", (e) => {
        // find relative form
        const secform = e.target.closest(".section--form");
        const form = secform.querySelector(".form");

        // add collapse animation to form in same time it takes to hide entire section
        this._closeForm(form, secform);
      });
    });

    body.addEventListener("click", (e) => {
      if (e.target.closest(".form")) return;
      if (e.target.closest(".item-el--footer")) return;
      if (e.target.closest(".lang-container--nav")) return;
      if (e.target.closest(".section--cards")) return;
      if (e.target.closest(".learn-page--container")) return;

      document.querySelectorAll(".form").forEach((form) => {
        if (form.classList.contains("hidden")) return;
        this._closeForm(form, form.closest(".section--form"));
      });
    });
  }
}

export default new HomeView();
