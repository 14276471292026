import { musicGenres, titleTopics } from "./helpers.js";

class ArticleView {
  #blogContainer = document.querySelector(".blog-container");
  #topicTitle = document.querySelector(".topic-title");
  // #secBlog = document.querySelector(".blog-container");

  // listenAnimations() {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         entry.target.classList.add("show");
  //       } else {
  //         entry.target.classList.remove("show");
  //       }
  //     });
  //   });

  //   observer.observe(this.#topicTitle);
  // }

  detectPage() {
    const id = location.href?.split("#")[1];

    // insert topic title
    if (!id) return;
    this.#topicTitle.textContent = `${titleTopics[id][0]}`;

    this._insertContent(id);
  }

  _insertContent(id) {
    if (!this.#blogContainer) return;
    this.#blogContainer.innerHTML = "";

    const topicArr = musicGenres[id];

    topicArr.forEach((element) => {
      this._insertHtml(element.id, element.title, element.text, element.href);
    });
  }

  _insertHtml(id, title, text, href) {
    let html = `
    <a class="article" href="https://scienceofmusicschool.com/${id}/${href}">
      <h2 class="article-title">${title}</h2>
      <p class="article-text">
       ${text}
      </p>
      <p class="read-more">Continue reading...</p>
    </a>
    `;

    this.#blogContainer.insertAdjacentHTML("beforeend", html);
  }
}
export default new ArticleView();
