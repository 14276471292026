import { courseForm } from "../js/helpers.js";

class LearnView {
  #allCourseEl = document.querySelectorAll("[data-kind]");
  #allIcons = document.querySelectorAll(".icon--item");
  #allExplanations = document.querySelectorAll(".explanation--text");
  #inquiryBtns = document.querySelectorAll(".card--btn");
  #formSelector = document.querySelector("#courses");
  #learnForm = document.querySelector(".section--learn-form");

  _insertformData(id) {
    this.#allCourseEl.forEach((el) => {
      el.textContent = courseForm[id][el.dataset.kind];
    });
    // replace the option bar with choose own
    this.#formSelector.value = "";
  }
  _revealIconContent(id) {
    HomeView._reveal(
      document.querySelector(`#${id}`).querySelector(".explanation--text")
    );
  }

  _hideIconContent(id) {
    HomeView._hide(
      document.querySelector(`#${id}`).querySelector(".explanation--text")
    );
  }

  inquiryListener() {
    this.#inquiryBtns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        // reveal form
        this.#learnForm.classList.remove("hidden");

        this._insertformData(e.target.closest(".card").id);

        // remove hash from url
        // window.location.href.split("#")[0];

        return;
      });
    });
  }

  iconListener() {
    this.#allIcons.forEach((icon) => {
      icon.addEventListener("mouseover", (e) => {
        const id = e.target.closest("li").id;
        this._revealIconContent(id);
      });
    });

    this.#allExplanations.forEach((expl) => {
      expl.addEventListener("mouseout", (e) => {
        const id = e.target.closest("li").id;
        this._hideIconContent(id);
      });
    });
  }
}

export default new LearnView();

// #html = document.querySelector("html");
// #learnPage = document.querySelector(".learn-page--container");
// #allLangTexts = document.querySelectorAll("[data-lang]");
// #navHrefs = document.querySelectorAll(".nav--href");

// setLearnLang() {
//   const lang = window.location.hash.substring(1);
//   this._translate(lang).then(() => {
//     this.#learnPage.classList.remove("invisible");
//   });
// }

// _translate(lang) {
//   // html direction
//   this.#html.dir = langFileLearn[lang].dir;
//   this.#html.lang = langFileLearn[lang].lang;

//   // translate text
//   this.#allLangTexts.forEach((text) => {
//     const textId = text.dataset.lang;
//     if (!textId) return;
//     text.textContent = langFileLearn[lang][textId];
//   });

//   // set nav hrefs
//   HomeView.setHrefs(this.#navHrefs, lang);

//   return Promise.resolve();
// }
