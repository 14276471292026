// import observeAnimation from "../js/helpers.js";

class BlogView {
  #navBlog = document.querySelector(".nav-blog");
  #bottomContainer = document.querySelector(".blog-img-container-box-end");
  #allAnimations = document.querySelectorAll(".hidden-animation");

  insertNavHtml() {
    this.#navBlog.innerHTML = "";
    let html = `
      <a class="container-logo" href="../home.html">
        <img class="logo-img" src="https://science-of-music-temp.netlify.app/logo.63dd0f83.png" />
        <p class="logo-text">Science of Music</p>
      </a>
      <ul class="list-nav">
        <li class="list-item">
          <a href="../learn.html">Courses</a>
        </li>
        <li class="list-item">
          <a href="../read.html">Science</a>
        </li>
      </ul>
    `;
    this.#navBlog.insertAdjacentHTML("beforeend", html);
  }

  insertDecor() {
    // bottom container
    this.#bottomContainer.innerHTML = "";
    let htmlBottom = `
      <div class="blog-img-container-end">
        &nbsp;
      </div>
    `;

    this.#bottomContainer.insertAdjacentHTML("beforeend", htmlBottom);
  }

  listenAnimations() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    this.#allAnimations?.forEach((anime) => {
      observer.observe(anime);
    });
  }
}
export default new BlogView();
