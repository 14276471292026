import ArticleView from "./articleView.js";
import BlogView from "./blogView.js";
import LearnView from "./learnView.js";
import HomeView from "./homeView.js";
import ReadView from "./readView.js";

const init = function () {
  const contactIcons = document.querySelectorAll(".icon--contact");
  const exitIcons = document.querySelectorAll(".icon--close-form");
  const body = document.querySelector("body");

  // (function insertBodyParts() {
  //   // NAV INSERTION
  //   document
  //     .querySelector(".section--nav")
  //     .insertAdjacentHTML("afterbegin", htmlBody.nav);
  // })();

  // document.addEventListener("DOMContentLoaded", () => {
  //   const curPage = window.location.pathname.substring(1);
  //   if (curPage === "home.html") HomeView.setHomeLang();
  //   if (curPage === "learn.html") LearnView.setLearnLang();
  // });

  HomeView.listenForm(contactIcons, exitIcons, body);
  // HomeView.listenHomeAnimations();

  LearnView.iconListener();
  LearnView.inquiryListener();

  // ReadView.listenAnimations();

  ArticleView.detectPage();
  // ArticleView.listenAnimations();

  BlogView.insertNavHtml();
  BlogView.insertDecor();
  BlogView.listenAnimations();
};

init();
